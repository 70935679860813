import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProductHeader from "./ProductHeader";
import ProductDescription from "./ProductDescription";
import ProductFooter from "./ProductFooter";

const ProductComponent = ({ product }) => {
  const navigate = useNavigate();
  const {isLoggedIn} = useSelector((state) => state.user);
  const productKey = product.productId.replace(/-/g, "_").toUpperCase();
  const productName =
    product.labels[
      `PRODUCT_MANAGEMENT_${productKey}_${product.i18nLabels
        .split(", ")[0]
        .toUpperCase()}`
    ];
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const onProductClick = () => {
    if (isLoggedIn) {
      navigate(`/product/${product.productId}`);
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="flex flex-col gap-4 bg-white p-3 rounded-lg border-primary border-2 text-ellipsis overflow-hidden">
      <ProductHeader
        productKey={productKey}
        product={product}
        productName={productName}
        imageBaseUrl={imageBaseUrl}
      />
      <ProductDescription
        product={product}
        productName={productName}
        imageBaseUrl={imageBaseUrl}
        productKey={productKey}
      />
      <ProductFooter product={product} onProductClick={onProductClick} />
    </div>
  );
};

export default ProductComponent;
