import React, { useEffect, useState } from "react";

import Card from "../common/Card";
import { useSelector } from "react-redux";
import Spinner from "../common/Spinner";

const Register = ({ onConfirm, handleBack, isRTL, t }) => {
  const { loading } = useSelector((state) => state.user);
  const [codes, setCodes] = useState(Array(5).fill(""));

  useEffect(() => {
    // Obtener el código del operador desde las variables de entorno
    const operatorCode = process.env.REACT_APP_PHONE_OPERATOR_DISPLAY_CODE || "05";
    
    // Actualizar la posición 0 del array codes
    const newCodes = [...codes];
    newCodes[0] = operatorCode;
    setCodes(newCodes);
  }, []);

  const handleChange = (index, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    
    const newCodes = [...codes];
    newCodes[index] = numericValue;

    setCodes(newCodes);

    // Resto del código para el manejo del foco
    if (index > 0 && numericValue.length === 2) {
        const nextInput = document.getElementById(`code-${index + 2}`);
        if (nextInput) {
            nextInput.focus();
        }
    }
    if (numericValue.length === 0 && index > 0) {
        const previousInput = document.getElementById(`code-${index}`);
        if (previousInput) {
            previousInput.focus();
        }
    }
  };

  const getPhoneFixed = () => {
    let phoneFixed = codes.join("");
    phoneFixed = phoneFixed.replace(process.env.REACT_APP_PHONE_OPERATOR_REPLACEMENT_DIGITS.split("||")[0], process.env.REACT_APP_PHONE_OPERATOR_REPLACEMENT_DIGITS.split("||")[1]);
    return phoneFixed;
  }

  const isConfirmEnabled = () =>  {
    let phoneFixed = getPhoneFixed();
    return phoneFixed.length == process.env.REACT_APP_PHONE_DIGITS_SIZE;
  }

  return (
    <div className="flex flex-col items-center justify-center mt-8 mx-4">
      <Card title={t("login.set_phone")}>
        <div className="flex flex-col items-center justify-center mt-6 mx-4 mb-6">
          <div className="flex mb-2 space-x-0">
            {codes.map((code, index) => {
              return index === 0 ? (
                <div key={index}>
                  <label htmlFor={`code-${index + 1}`} className="sr-only">
                    {`${index + 1} code`}
                  </label>
                  <input
                    type="tel"
                    maxLength="2"
                    id={`code-${index + 1}`}
                    value={code}
                    onChange={(e) => handleChange(index, e.target.value)}
                    className={`block w-16 h-14 py-3 text-2xl font-extrabold text-center text-white bg-lightgray placeholder:text-white placeholder:opacity-40 ${
                      index === 0 && "rounded-l-xl bg-primary rounded-r-none"
                    }`}
                    required
                    placeholder={process.env.REACT_APP_PHONE_OPERATOR_DISPLAY_CODE}
                    disabled
                  />
                </div>
              ) : (
                <div key={index}>
                  <label htmlFor={`code-${index + 1}`} className="sr-only">
                    {`${index + 1} code`}
                  </label>
                  <input
                    type="tel"
                    maxLength="2"
                    id={`code-${index + 1}`}
                    value={code}
                    onChange={(e) => handleChange(index, e.target.value)}
                    className={`block w-16 h-14 py-3 text-2xl font-extrabold text-center text-white bg-lightgray placeholder:text-white placeholder:opacity-40 rounded-none`}
                    required
                    placeholder="00"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Card>
      <div className="flex flex-col items-center text-center justify-center mt-6 mx-4 mb-6">
        <p className="text-sm mt-8">{t("login.pin_is_sent_to")}</p>
      </div>

      {/* Buttons */}
      <div className="flex-col fixed bottom-0 left-0 w-full  p-4 flex justify-between items-center">
        {loading ? (
          <div className="flex justify-center items-center mb-2">
            <Spinner />
          </div>
        ) : (
          <button
            onClick={
              isConfirmEnabled() ? () => onConfirm(getPhoneFixed()) : undefined
            }
            className={`bg-primary text-white px-4 py-2 rounded-lg w-full my-1 min-h-12 font-bold text-lg ${
              isConfirmEnabled() ? "" : "opacity-50 cursor-not-allowed"
            }`}
            disabled={!isConfirmEnabled()}
          >
            {t("login.confirm")}
          </button>
        )}
        <button
          onClick={handleBack}
          className="bg-secondary text-white px-4 py-2 rounded-lg w-full my-1 min-h-12 font-bold"
        >
          {t("login.return")}
        </button>
      </div>
    </div>
  );
};

export default Register;
