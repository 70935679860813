import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Header from "./common/Header";
import { useValidateRTL } from "../utils/hooks/useCommon";
import LanguageSelector from "./common/LanguageSelector";
import { postLogout } from "../redux/slices/userSlice";

const SidebarMenu = ({
  closeAction,
  isOpen,
  handleOptionClick,
  handleLogout,
}) => {
  const { t } = useTranslation();
  const isRtl = useValidateRTL();
  const { isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="fixed inset-0 z-40" onClick={closeAction}>
        <div
          className={`fixed top-36 w-60 bg-white p-4 z-50 shadow-lg shadow-slate-400 transition-transform duration-700 ease-in-out transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }
          ${
            isRtl ? "right-0" : "left-0"
          }`}
        >
          {/* <Header
            showTranslation={false}
            closable={true}
            closeAction={closeAction}
            styles={{ padding: "0px", marginBottom: "20px" }}
          /> */}
          <ul onClick={handleOptionClick}>
            {isLoggedIn ? (
              <li
                className="text-black py-4 border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
                onClick={handleLogout}
              >
                {t("menu.logout")}
              </li>
            ) : (
              <li
                className="text-black py-4 border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
                onClick={handleOptionClick}
              >
                {t("menu.loginLong")}
              </li>
            )}
            <li
              className="text-black py-4 border-b border-gray-300 hover:bg-gray-100 cursor-pointer inline-flex w-full items-center"
              onClick={handleOptionClick}
            >
              <span className={`${isRtl ? "ml-2" : "mr-2"}`}>
                {t("menu.language")}
              </span>
              <div className="mx-2">
                <LanguageSelector />
              </div>
            </li>
            {isLoggedIn && (
              <li
                className="text-black py-4 border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
                onClick={() => navigate("/my-account")}
              >
                {t("general.myAccount")}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

const MainMenu = ({ showSidebar = true, showLogin = true, msisdn = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isRtl = useValidateRTL();
  const { t } = useTranslation();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Selectors
  const user = useSelector((state) => state.user);
  const title = useSelector((state) => state.design.title);

  // Effects
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Desactiva el scroll
    } else {
      document.body.style.overflow = "unset"; // Reactiva el scroll
    }
    return () => {
      document.body.style.overflow = "unset"; // Asegura que se reactive al desmontar
    };
  }, [isOpen]);

  const handleOptionClick = (event) => {
    event.stopPropagation();
  };

  const handleLogout = () => {
    dispatch(postLogout({ authCode: user.authCode })).then(() => {
      setIsOpen(false);
      navigate("/");
    });
  };
  return (
    <>
      <div
        className={`bg-primary p-2 px-4 flex justify-between items-center min-h-14 ${
          isRtl ? "rtl" : ""
        }`}
      >
        {showSidebar && (
          <button
            className="text-white focus:outline-none"
            onClick={toggleMenu}
          >
            <svg
              width="26"
              height="18"
              viewBox="0 0 26 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H24M2 9H24M2 16H24"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        )}
        <div className="flex items-center">
          <span className="text-white font-bold">{title}</span>
        </div>
        {showLogin && user.msisdn !== "" && user.isLoggedIn ? (
          <input
            type="number"
            value={user.phone}
            placeholder={
              user.msisdn
                ? user.msisdn
                    .replace(
                      /(\d{3})(\d{3})(\d{2})(\d{2})(?=\d)/g,
                      "$1 $2 $3 $4 "
                    )
                    .trim()
                : ""
            }
            disabled
            className="p-2 rounded border border-white w-40 bg-opacity-40 bg-white text-white placeholder-white text-center ltr"
          />
        ) : (
          showLogin && (
            <button
              className="p-2 rounded border border-white w-40 bg-opacity-40 bg-white text-white placeholder-white text-center font-medium "
              onClick={() => navigate("/login")}
            >
              {t("menu.login")}
            </button>
          )
        )}
      </div>
      {/* Sidebar */}
      {isOpen && (
        <SidebarMenu
          closeAction={() => setIsOpen(false)}
          isOpen={isOpen}
          handleOptionClick={handleOptionClick}
          handleLogout={handleLogout}
        />
      )}
    </>
  );
};

export default MainMenu;
