import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  authURL: process.env.REACT_APP_AUTH_URL,
  bitdefenderCentralURL: process.env.REACT_APP_BITDEFENDER_CENTRAL_URL
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json';
    if (config.url.startsWith('/adsafe')) {
      config.url = config.authURL + config.url;
    } else if (config.url.startsWith('redir')) {
      config.url = config.bitdefenderCentralURL + config.url;
    } else if (config.url.startsWith('http')) {
      // Do nothing because it's an external URL
    } else {
      config.url = config.baseURL + config.url;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;